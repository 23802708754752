/* eslint-disable quotes */
<template>
  <div class="container-fluid mt-2">
    <form-wizard
      color="#0128d6"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <div class="d-flex justify-content-end">
        <b-spinner
          v-if="load === 'true'"
          class="mr-1"
          variant="primary"
        /></div>

      <!-- accoint details tab  -->
      <tab-content
        :title="$t('Admission')"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row :class="reverse">
            <b-col
              cols="12"
              class="mb-2 mt-3"
              :class="reverse"
            >
              <feather-icon
                icon="BookOpenIcon"
                class="mr-25"
              />
              <span
                class="mb-0 font-weight-bold h5"
              > {{ $t("Reserveyourplace") }} </span>
              <small class="text-muted">
                {{ $t("DiplomaType") }} ,{{ $t("Specialty") }} , {{ $t("Level") }}
              </small>
            </b-col>

            <b-col
              v-if="lead.specialite_id == null"
              md="4"
            >
              <b-form-group
                :label="$t('DiplomaType')"
                label-for="parcour"
              >
                <validation-provider
                  #default="{ errors }"
                  name="parcour"
                  rules="required"
                >
                  <v-select
                    v-model="parcour"
                    :clearable="false"
                    :placeholder="$t('ChooseDiplomaType')"
                    :label="label"
                    :options="parcours"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="lead.specialite_id != null"
              md="4"
            >
              <b-form-group
                :label="$t('DiplomaType')"
                label-for="parcour"
              >
                <validation-provider
                  #default="{ errors }"
                  name="parcour"
                  rules="required"
                >
                  <v-select
                    disabled
                    :value="speciality.parcour"
                    :label="label"
                    :clearable="false"
                    placeholder="Choisir Parcour"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="lead.specialite_id == null"
              md="4"
            >
              <b-form-group
                :label="$t('Specialty')"
                label-for="specialite"
              >
                <validation-provider
                  #default="{ errors }"
                  name="specialite"
                  rules="required"
                >
                  <v-select
                    v-model="specialite"
                    :clearable="false"
                    :label="label"
                    :options="mySpecialites"
                    placeholder="Choisir Spécialité"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="lead.specialite_id != null"
              md="4"
            >
              <b-form-group
                :label="$t('Specialty')"
                label-for="specia"
              >
                <validation-provider
                  #default="{ errors }"
                  name="specia"
                  rules="required"
                >
                  <v-select
                    disabled
                    :value="speciality"
                    :clearable="false"
                    :label="label"
                    :options="mySpecialites"
                    :placeholder="$t('ChooseSpecialty')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('Level')"
                label-for="niveau"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Niveau"
                  rules="required"
                >
                  <v-select
                    v-model="lead.niveau"
                    :disabled="ok"
                    :clearable="false"
                    label="Niveau"
                    :options="myNiveaux"
                    :placeholder="$t('Level')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row :class="reverse">
            <b-col
              cols="12"
              class="mb-2 mt-3"
              :class="reverse"
            >
              <feather-icon
                icon="UserIcon"
                class="mr-25"
              />
              <span class="mb-0 font-weight-bold h5"> {{ $t("PersonalData") }} </span>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('Civility')"
                label-for="civilite"
              >
                <validation-provider
                  #default="{ errors }"
                  name="civilite"
                  rules="required"
                >
                  <v-select
                    v-model="lead.civilite"
                    :disabled="ok"
                    :clearable="false"
                    label="Civilité"
                    :options="civilites"
                    :placeholder="$t('Civility')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('FirstName')"
                label-for="nom"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nom"
                  rules="required"
                >
                  <b-form-input
                    id="nom"
                    v-model="lead.nom"
                    :disabled="ok"
                    :placeholder="$t('FirstName')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('LastName')"
                label-for="prenom"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Prenom"
                  rules="required"
                >
                  <b-form-input
                    id="prenom"
                    v-model="lead.prenom"
                    :disabled="ok"
                    :placeholder="$t('LastName')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('CINPasseport')"
                label-for="cin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cin"
                  rules="required"
                >
                  <b-form-input
                    id="cin"
                    v-model="lead.cin"
                    :disabled="ok"
                    :placeholder="$t('CINPasseport')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('Country')"
                label-for="pays"
              >
                <validation-provider
                  #default="{ errors }"
                  name="pays"
                  rules="required"
                >
                  <b-form-input
                    id="cin"
                    v-model="lead.pays"
                    :disabled="ok"
                    :placeholder="$t('Country')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <label for="example-datepicker">{{ $t("Date of Birth") }}</label>
              <flat-pickr
                v-model="lead.dateNaissance"
                class="form-control invoice-edit-input mb-1"
                :disabled="ok"
              />

            </b-col>

            <b-col md="3">
              <b-form-group
                :label="$t('Place of birth')"
                label-for="lieuNaissance"
              >
                <validation-provider
                  #default="{ errors }"
                  name="lieuNaissance"
                  rules="required"
                >
                  <b-form-input
                    id="lieuNaissance"
                    v-model="lead.lieuNaissance"
                    :disabled="ok"
                    :placeholder="$t('Place of birth')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                :label="$t('Phone')"
                label-for="telephone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="telephone"
                  rules="required"
                >
                  <b-form-input
                    id="telephone"
                    v-model="lead.telephone"
                    :disabled="ok"
                    :placeholder="$t('Phone')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                :label="$t('Email')"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="lead.email"
                    disabled
                    :placeholder="$t('Email')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                :label="$t('Address')"
                label-for="adress"
              >
                <validation-provider
                  #default="{ errors }"
                  name="adress"
                  rules="required"
                >
                  <b-form-input
                    id="adress"
                    v-model="lead.adress"
                    :disabled="ok"
                    :placeholder="$t('Address')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row :class="reverse">
            <b-col
              cols="12"
              class="mb-2 mt-3"
              :class="reverse"
            >
              <feather-icon
                icon="BookmarkIcon"
                class="mr-25"
              />
              <span class="mb-0 font-weight-bold h5">
                {{ $t("School curriculum") }}
              </span>
            </b-col></b-row>
          <b-row :class="reverse">
            <b-col md="3">
              <b-form-group
                :label="$t('Baccalaureate')"
                label-for="bac"
              >
                <validation-provider
                  #default="{ errors }"
                  name="bac"
                  rules="required"
                >
                  <v-select
                    v-model="lead.bac"
                    :disabled="ok"
                    :clearable="false"
                    label="Baccalauréat"
                    :options="baccalaureats"
                    :placeholder="$t('Baccalaureate')"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                :label="$t('TitleObt')"
                label-for="typeBac"
              >
                <validation-provider
                  #default="{ errors }"
                  name="titre"
                  rules="required"
                >
                  <b-form-input
                    id="typeBac"
                    v-model="lead.typeBac"
                    :disabled="ok"
                    :placeholder="$t('TitleObt')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                :label="$t('Year of graduation')"
                label-for="anneeBac"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Prenom"
                  rules="required"
                >
                  <b-form-input
                    id="anneeBac"
                    v-model="lead.anneeBac"
                    :disabled="ok"
                    :placeholder="$t('Year of graduation')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                :label="$t('Upload of the Bac diploma or equivalent')"
                label-for="baccalaureat"
              >
                <validation-provider
                  #default="{ errors }"
                  name="baccalaureat"
                  rules="required"
                >
                  <b-form-file
                    v-model="lead.uploadBac"
                    :disabled="ok"
                    :placeholder="$t('Upload of the Bac diploma or equivalent')"
                    drop-placeholder="Drop file here..."
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group
                :label="$t('Last Diploma other than the Bac')"
                label-for="diplome"
              >
                <validation-provider
                  #default="{ errors }"
                  name="diplome"
                >
                  <v-select
                    v-model="lead.diplome"
                    :disabled="ok"
                    :clearable="false"
                    label="Dernier Diplôme autre que le Bac "
                    :options="diplomes"
                    :placeholder="$t('Last Diploma other than the Bac')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                :label="$t('TitleObt')"
                label-for="typeDiplome"
              >
                <validation-provider
                  #default="{ errors }"
                  name="typeDiplome"
                >
                  <b-form-input
                    id="typeDiplome"
                    v-model="lead.typeDiplome"
                    :disabled="ok"
                    :placeholder="$t('TitleObt')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                :label="$t('Year of graduation')"
                label-for="anneeDiplome"
              >
                <validation-provider
                  #default="{ errors }"
                  name="anneeDiplome"
                >
                  <b-form-input
                    id="anneeDiplome"
                    v-model="lead.anneeDiplome"
                    :disabled="ok"
                    :placeholder="$t('Year of graduation')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                :label="$t('Upload Latest Diploma')"
                label-for="fileDiplome"
              >
                <validation-provider
                  #default="{ errors }"
                  name="fileDiplome"
                >
                  <b-form-file
                    v-model="lead.uploadDiplome"
                    :disabled="ok"
                    :placeholder="$t('Upload Latest Diploma')"
                    drop-placeholder="Drop file here..."
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content
        :title="$t('Pre-registration')"
        :before-change="admission"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-table
            responsive="sm"
            :items="items"
          >
            <template
              #cell(_)="data"
            >
              <b-badge
                class="badge-glow"
                pill
                :variant="data.item._.variant"
              >
                <feather-icon
                  :icon="data.item._.icon"
                  class="mr-25"
                />
                <span> {{ data.item._.status }}</span>
              </b-badge>
              <h5
                v-if="
                  data.item.Tâche == 'Choix du parcours' ||
                    data.item.Task == 'Choice of class'
                "
                class="mt-1"
              >
                {{ $t("DiplomaType") }} : {{ speciality.parcour.nom }}
              </h5>
              <h5
                v-if="
                  data.item.Tâche == 'Choix du parcours' ||
                    data.item.Task == 'Choice of class'
                "
              >
                {{ $t("Specialty") }} :{{ speciality.nom }}
              </h5>
              <h5
                v-if="
                  data.item.Tâche == 'Choix du parcours' ||
                    data.item.Task == 'Choice of class'
                "
              >
                {{ $t("Level") }} : {{ lead.niveau }}
              </h5>

              <b-button
                v-if="
                  data.item.Tâche == 'Attestation de préinscription' || data.item.Task == 'Pre-registration certificate' || data.item.الخانة ==='شهادة التسجيل المسبق'
                "
                class="ml-1"
                variant="primary"
                @click="generateReport"
              >
                {{ $t('Télécharger') }}
              </b-button>
            </template>
          </b-table>
        </validation-observer>
      </tab-content>
      <!-- address  -->
      <tab-content
        :title="$t('SubscriptionRequest')"
        :before-change="inscription"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <b-row :class="reverse">
            <b-col
              cols="12"
              class="mb-2"
              :class="reverse"
            >
              <h5 class="mb-0">
                {{ $t("Dossier d'inscription") }}
              </h5>
              <small
                class="text-muted"
              > {{ $t("Remplir le formulaire") }}</small>
            </b-col>
            <b-col
              v-if="inscri == false"
              md="6"
            >
              <b-form-group
                :label="$t('Upload passeport ou CIN')"
                label-for="copieCin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="copieCin"
                  rules="required"
                >
                  <b-form-file
                    v-model="lead.uploadCin"
                    :placeholder="$t('Placeholder-file')"
                    drop-placeholder="Drop file here..."
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="inscri"
              md="6"
            >
              <b-form-group
                :label="$t('Upload passeport ou CIN')"
                label-for="copieCin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="copieCin"
                  rules="required"
                >
                  <b-form-input
                    v-model="lead.uploadCin"
                    disabled
                    :placeholder="$t('Placeholder-file')"
                    drop-placeholder="Drop file here..."
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="inscri == false"
              md="6"
            >
              <b-form-group
                :label="$t('Upload Acte de naissance')"
                label-for="acteNaiss"
              >
                <validation-provider
                  #default="{ errors }"
                  name="acteNaiss"
                  rules="required"
                >
                  <b-form-file
                    v-model="lead.uploadActNaiss"
                    :disabled="inscri"
                    :placeholder="$t('Placeholder-file')"
                    drop-placeholder="Drop file here..."
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="inscri"
              md="6"
            >
              <b-form-group
                :label="$t('Upload Acte de naissance')"
                label-for="acteNaiss"
              >
                <validation-provider
                  #default="{ errors }"
                  name="acteNaiss"
                  rules="required"
                >
                  <b-form-input
                    v-model="lead.uploadActNaiss"
                    disabled
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="inscri == false"
              md="6"
            >
              <b-form-group
                :label="$t('Upload Relevés de Notes')"
                label-for="releveNote"
              >
                <validation-provider
                  #default="{ errors }"
                  name="releveNote"
                  rules="required"
                >
                  <b-form-file
                    v-model="lead.uploadNote"
                    :placeholder="$t('Placeholder-file')"
                    drop-placeholder="Drop file here..."
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="inscri"
              md="6"
            >
              <b-form-group
                :label="$t('Upload Relevés de Notes')"
                label-for="releveNote"
              >
                <validation-provider
                  #default="{ errors }"
                  name="releveNote"
                  rules="required"
                >
                  <b-form-input
                    v-model="lead.uploadNote"
                    disabled
                    :placeholder="$t('Placeholder-file')"
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- social link -->
      <tab-content
        :title="$t('PaymentandServices')"
        :before-change="paiement"
      >
        <validation-observer
          ref="payement"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
              :class="reverse"
            >
              <h5 class="mb-0">
                {{ $t('Paiement scolarité') }}
              </h5>
              <small
                class="text-muted"
              >{{ $t('Informations de paiement') }}</small>
            </b-col>

            <b-col md="6">
              <b-form-group
                :label="$t('Méthode')"
                label-for="methode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="methode"
                  rules="required"
                >
                  <v-select
                    v-model="lead.methodePaiement"
                    :disabled="paier"
                    :clearable="false"
                    label="Méthode"
                    :options="methodes"
                    placeholder="Choisir Méthode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('Devise')"
                label-for="devise"
              >
                <validation-provider
                  #default="{ errors }"
                  name="devise"
                  rules="required"
                >
                  <v-select
                    v-model="lead.devise"
                    :disabled="paier"
                    :clearable="false"
                    label="Devise"
                    :options="devises"
                    placeholder="Choisir Devise"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('Tranches')"
                label-for="tranche"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tranche"
                  rules="required"
                >
                  <v-select
                    v-model="lead.tranche"
                    :disabled="paier"
                    :clearable="false"
                    :label="$t('Tranches')"
                    :options="tranches"
                    placeholder="Choisir tranche"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="paier == false"
              md="6"
            >
              <b-form-group
                :label="$t('Pièce justificative')"
                label-for="uploadPaie"
              >
                <validation-provider
                  #default="{ errors }"
                  name="uploadPaie"
                  rules="required"
                >
                  <b-form-file
                    v-model="lead.uploadPaiement"
                    :placeholder="$t('Placeholder-file')"
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="paier"
              md="6"
            >
              <b-form-group
                :label="$t('Pièce justificative')"
                label-for="uploadPaieme"
              >
                <validation-provider
                  #default="{ errors }"
                  name="uploadPaieme"
                  rules="required"
                >
                  <b-form-input
                    v-model="lead.uploadPaiement"
                    disabled
                    :placeholder="$t('Placeholder-file')"
                    drop-placeholder="Drop file here..."
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="demo-spacing-0">
            <b-alert
              variant="warning"
              show
            >
              <h4
                class="alert-heading"
                :class="reverse"
              >
                {{ $t('Consignes') }}
              </h4>
              <span :class="reverse">
                {{ $t('Envoyer la somme') }}</span>
              <br>
              <div
                class="mt-1"
                :class="reverse"
              >
                RIB :  10608006104587678886
              </div>
              <div
                class="mt-1"
                :class="reverse"
              >
                IBAN : TN 59 10608006104587678886
              </div>

              <div
                class="mt-1"
                :class="reverse"
              >
                BIC : STBKTNTT
              </div>
              <div
                class="mt-1"
                :class="reverse"
              >
                {{ $t('Agence bancaire') }}: STB Dar Chaabane
              </div>

            </b-alert>
          </div>
        </validation-observer>
      </tab-content>
      <tab-content :title="$t('Confirmation')">
        <validation-observer
          ref="socialRules"
          tag="form"
        >
          <b-table
            responsive="sm"
            :items="confirmation"
          >
            <template #cell(_)="data">
              <b-badge
                class="badge-glow"
                pill
                :variant="data.item._.variant"
              >
                <feather-icon
                  :icon="data.item._.icon"
                  class="mr-25"
                />
                <span> {{ data.item._.status }}</span>
              </b-badge>
              <h5
                v-if="data.item.Tâche == 'Choix du parcours'"
                class="mt-1"
              >
                Parcour : {{ speciality.parcour.nom }}
              </h5>
              <h5 v-if="data.item.Tâche == 'Choix du parcours'">
                Spécialité :{{ speciality.nom }}
              </h5>
              <h5 v-if="data.item.Tâche == 'Choix du parcours'">
                Niveau : {{ lead.niveau }}
              </h5>
              <b-button
                v-if="
                  data.item.Tâche == 'Attestation de paiement'|| data.item.Task == 'Proof of payment' || data.item.الخانة === 'إثبات دفع'
                "
                class="ml-1"
                variant="primary"
                @click="generatePaiement"
              >
                {{ $t('Télécharger') }}

              </b-button>
              <b-button
                v-if="
                  data.item.Tâche == 'Attestation inscription' || data.item.Task == 'Registration certificate' || data.item.الخانة == 'شهادة التسجيل'
                "
                class="ml-1"
                variant="primary"
                @click="generateInscription"
              >
                {{ $t('Télécharger') }}

              </b-button>
            </template>
          </b-table>
        </validation-observer>
      </tab-content>
    </form-wizard>
    <vue-html2pdf
      ref="paiement"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Attestation"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <html
          lang="en"
          dir="ltr"
        >

          <body style="margin:1cm;background-color:white;color:black">

            <div style="display:flex; justify-content:space-between ;align-items:center">
              <div>
                <img
                  src="/logo-mit.png"
                  alt="mit"
                  width="200px"
                  height="auto"
                >
              </div>
              <div>
                <h5
                  style="color: black;
                text-align: center;
                font-weight: bold"
                >
                  Ecole méditerranéenne privée <br> de nabeul
                </h5>
              </div>
              <div>
                <img
                  src="/mit-enc.jpg"
                  alt="mit"
                  width="75px"
                  height="auto"
                >
              </div>

            </div>
            <div>
              <hr>
              <h5
                style="margin-top: 80px;
                font-size: 25px;
                line-height: 45px;
                color: black;
                text-align: center;
                font-weight: bold"
              > ATTESTATION DE PAIEMENT
              </h5>

              <h5
                style="font-size: 20px;
                line-height: 25px;
                color: black;
                text-align: center;
                font-weight: bold"
              >Année Universitaire : 2021/2022
              </h5>
              <p style="font-size: 16px; margin-top: 50px;">
                Le directeur de l'école Méditerranéenne privée de nabeul soussignée atteste que :
              </p>
              <p style="font-size: 20px; margin-top: 10px; text-align: center; font-weight: bold; ">
                {{ lead.nom }} {{ lead.prenom }}
              </p>

              <p style="font-size: 16px; margin-top: 10px;"> Titulaire de la C.I .N (Passeport pour les étrangers) n° : {{ lead.cin }}
              </p>
              <p style="font-size: 16px; margin-top: 10px;"> Né le : {{ lead.dateNaissance }} à {{ lead.lieuNaissance }}
              </p><p style="font-size: 16px; margin-top: 10px;">  inscrit en :
              </p>
              <table
                style="border: 1px solid black;   width: 100%;
                border-collapse: collapse;"
              >
                <tr>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    Parcour
                  </th>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    Spécialité
                  </th>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    niveau
                  </th>
                </tr>
                <tr v-if="speciality">
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    {{ speciality.parcour.nom }}
                  </th>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    {{ speciality.nom }}
                  </th>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    {{ lead.niveau }}
                  </th>
                </tr>
              </table>
              <p style="font-size: 16px; margin-top: 20px;"> A payé le frais de scolarité de la première tranche au titre de l'année universitaire 2021/2022.
              </p>
              <p style="font-size: 16px; margin-top: 10px;"> Cette attestation est d&eacute;livr&eacute;e &agrave; l'int&eacute;ress&eacute;e pour servir et valoir ce que de droit.
              </p>

              <br><br>

              <div style="text-align: right; margin-right: 100px;">
                <h5 style="margin-right: 10px;">
                  Le directeur
                </h5>
                <h5>Maalej Rabi</h5>
                <img
                  src="/sign.JPG"
                  alt="att"
                  width="400px"
                  height="auto"
                >
              </div>

            </div>
            <footer style="margin-top:100px;">
              <hr>
              <div style="display:flex; justify-content:space-between">
                <div style="display:flex;">
                  <feather-icon
                    icon="HomeIcon"
                    class="mr-25"
                  />
                  <h5>

                    Rue des princes, 8000 <br> Nabeul, Tunisie
                  </h5>
                </div>
                <div style="display:flex">
                  <feather-icon
                    icon="PhoneIcon"
                    class="mr-25"
                  />
                  <h5>
                    (+216) 72 220 507
                  </h5>
                </div>
                <div style="display:flex">
                  <feather-icon
                    icon="GlobeIcon"
                    class="mr-25"
                  />
                  <h5>
                    https://mit-nabeul.tn/
                  </h5>
                </div>
                <div />
              </div></footer>

          </body>
        </html>
      </section>
    </vue-html2pdf>
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Attestation"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <html
          lang="en"
          dir="ltr"
        >

          <body style="margin:1cm;background-color:white;color:black">

            <div style="display:flex; justify-content:space-between ;align-items:center">
              <div>
                <img
                  src="/logo-mit.png"
                  alt="mit"
                  width="200px"
                  height="auto"
                >
              </div>
              <div>
                <h5
                  style="color: black;
                text-align: center;
                font-weight: bold"
                >
                  Ecole méditerranéenne privée <br> de nabeul
                </h5>
              </div>
              <div>
                <img
                  src="/mit-enc.jpg"
                  alt="mit"
                  width="75px"
                  height="auto"
                >
              </div>

            </div>
            <div>
              <hr>
              <h5
                style="margin-top: 80px;
                font-size: 25px;
                line-height: 45px;
                color: black;
                text-align: center;
                font-weight: bold"
              > ATTESTATION DE PRE-INSCRIPTION
              </h5>

              <h5
                style="font-size: 20px;
                line-height: 25px;
                color: black;
                text-align: center;
                font-weight: bold"
              >Année Universitaire : 2021/2022
              </h5>
              <p style="font-size: 16px; margin-top: 50px;">
                Le directeur de l'école Méditerranéenne privée de nabeul soussignée atteste que :
              </p>
              <p style="font-size: 20px; margin-top: 10px; text-align: center; font-weight: bold; ">
                {{ lead.nom }} {{ lead.prenom }}
              </p>

              <p style="font-size: 16px; margin-top: 10px;"> Titulaire de la C.I .N (Passeport pour les étrangers) n° : {{ lead.cin }}
              </p>
              <p style="font-size: 16px; margin-top: 10px;"> Né le : {{ lead.dateNaissance }} à {{ lead.lieuNaissance }}
              </p><p style="font-size: 16px; margin-top: 10px;"> Est pré-inscrit en :
              </p>
              <table
                style="border: 1px solid black;   width: 100%;
                border-collapse: collapse;"
              >
                <tr>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    Parcour
                  </th>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    Spécialité
                  </th>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    niveau
                  </th>
                </tr>
                <tr v-if="speciality">
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    {{ speciality.parcour.nom }}
                  </th>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    {{ speciality.nom }}
                  </th>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    {{ lead.niveau }}
                  </th>
                </tr>
              </table>
              <p style="font-size: 16px; margin-top: 20px;"> En qualit&eacute; d'&eacute;tudiant qui b&eacute;n&eacute;ficiera d'une inscription r&eacute;guli&egrave;re pour l'ann&eacute;e universitaire 2021/2022.
              </p>
              <p style="font-size: 16px; margin-top: 10px;"> Cette attestation est d&eacute;livr&eacute;e &agrave; l'int&eacute;ress&eacute;e pour servir et valoir ce que de droit.
              </p>

              <br><br>

              <div style="text-align: right; margin-right: 100px;">
                <h5 style="margin-right: 10px;">
                  Le directeur
                </h5>
                <h5>Maalej Rabi</h5>
                <img
                  src="/sign.JPG"
                  alt="att"
                  width="400px"
                  height="auto"
                >
              </div>

            </div>
            <footer style="margin-top:100px;">
              <hr>
              <div style="display:flex; justify-content:space-between">
                <div style="display:flex;">
                  <feather-icon
                    icon="HomeIcon"
                    class="mr-25"
                  />
                  <h5>

                    Rue des princes, 8000 <br> Nabeul, Tunisie
                  </h5>
                </div>
                <div style="display:flex">
                  <feather-icon
                    icon="PhoneIcon"
                    class="mr-25"
                  />
                  <h5>
                    (+216) 72 220 507
                  </h5>
                </div>
                <div style="display:flex">
                  <feather-icon
                    icon="GlobeIcon"
                    class="mr-25"
                  />
                  <h5>
                    https://mit-nabeul.tn/
                  </h5>
                </div>
                <div />
              </div></footer>

          </body>
        </html>
      </section>
    </vue-html2pdf>

    <vue-html2pdf
      ref="inscription"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Attestation"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <html
          lang="en"
          dir="ltr"
        >

          <body style="margin:1cm;background-color:white;color:black">

            <div style="display:flex; justify-content:space-between ;align-items:center">
              <div>
                <img
                  src="/logo-mit.png"
                  alt="mit"
                  width="200px"
                  height="auto"
                >
              </div>
              <div>
                <h5
                  style="color: black;
                text-align: center;
                font-weight: bold"
                >
                  Ecole méditerranéenne privée <br> de nabeul
                </h5>
              </div>
              <div>
                <img
                  src="/mit-enc.jpg"
                  alt="mit"
                  width="75px"
                  height="auto"
                >
              </div>

            </div>
            <div>
              <hr>
              <h5
                style="margin-top: 80px;
                font-size: 25px;
                line-height: 45px;
                color: black;
                text-align: center;
                font-weight: bold"
              > ATTESTATION D'INSCRIPTION
              </h5>

              <h5
                style="font-size: 20px;
                line-height: 25px;
                color: black;
                text-align: center;
                font-weight: bold"
              >Année Universitaire : 2021/2022
              </h5>
              <p style="font-size: 16px; margin-top: 50px;">
                Le directeur de l'école Méditerranéenne privée de nabeul soussignée atteste que :
              </p>
              <p style="font-size: 20px; margin-top: 10px; text-align: center; font-weight: bold; ">
                {{ lead.nom }} {{ lead.prenom }}
              </p>

              <p style="font-size: 16px; margin-top: 10px;"> Titulaire de la C.I .N (Passeport pour les étrangers) n° : {{ lead.cin }}
              </p>
              <p style="font-size: 16px; margin-top: 10px;"> Né le : {{ lead.dateNaissance }} à {{ lead.lieuNaissance }}
              </p><p style="font-size: 16px; margin-top: 10px;"> Est inscrit en :
              </p>
              <table
                style="border: 1px solid black;   width: 100%;
                border-collapse: collapse;"
              >
                <tr>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    Parcour
                  </th>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    Spécialité
                  </th>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    niveau
                  </th>
                </tr>
                <tr v-if="speciality">
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    {{ speciality.parcour.nom }}
                  </th>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    {{ speciality.nom }}
                  </th>
                  <th
                    style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                  >
                    {{ lead.niveau }}
                  </th>
                </tr>
              </table>
              <p style="font-size: 16px; margin-top: 20px;"> En qualit&eacute; d'&eacute;tudiant qui b&eacute;n&eacute;ficiera d'une inscription r&eacute;guli&egrave;re pour l'ann&eacute;e universitaire 2021/2022.
              </p>
              <p style="font-size: 16px; margin-top: 10px;"> Cette attestation est d&eacute;livr&eacute;e &agrave; l'int&eacute;ress&eacute;e pour servir et valoir ce que de droit.
              </p>

              <br><br>

              <div style="text-align: right; margin-right: 100px;">
                <h5 style="margin-right: 10px;">
                  Le directeur
                </h5>
                <h5>Maalej Rabi</h5>
                <img
                  src="/sign.JPG"
                  alt="att"
                  width="400px"
                  height="auto"
                >
              </div>

            </div>
            <footer style="margin-top:100px;">
              <hr>
              <div style="display:flex; justify-content:space-between">
                <div style="display:flex;">
                  <feather-icon
                    icon="HomeIcon"
                    class="mr-25"
                  />
                  <h5>

                    Rue des princes, 8000 <br> Nabeul, Tunisie
                  </h5>
                </div>
                <div style="display:flex">
                  <feather-icon
                    icon="PhoneIcon"
                    class="mr-25"
                  />
                  <h5>
                    (+216) 72 220 507
                  </h5>
                </div>
                <div style="display:flex">
                  <feather-icon
                    icon="GlobeIcon"
                    class="mr-25"
                  />
                  <h5>
                    https://mit-nabeul.tn/
                  </h5>
                </div>
                <div />
              </div></footer>

          </body>
        </html>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueHtml2pdf from 'vue-html2pdf'

import {
  BRow,
  BCol,
  BAlert,
  BFormGroup,
  BFormInput,
  BTable,
  BBadge,
  BFormFile,
  BSpinner,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BAlert,
    VueHtml2pdf,
    BTable,
    BBadge,
    BFormFile,
    BSpinner,
    BButton,
    flatPickr,
  },
  data() {
    return {
      load: 'fasle',
      parcour: { nom: '' },
      users: [],
      lead: {},
      parcours: [{ nom: '' }],
      specialites: [],
      specialite: { nom: '' },
      niveaux: [],
      devises: ['TND'],
      diplomes: ['oui', 'equivalence'],
      payes: [
      ],

      selectedContry: '',
      selectedLanguage: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
    }
  },
  computed: {
    label() {
      if (this.$i18n.locale === 'fr') {
        return 'nom'
      }
      if (this.$i18n.locale === 'en') {
        return 'nom_anglais'
      }
      return 'nom_arabe'
    },
    tranches() {
      if (this.$i18n.locale === 'fr') {
        return ['Première tranche( 500 € / 500 $)']
      }
      if (this.$i18n.locale === 'en') {
        return ['First slice( 500 € / 500 $)']
      }
      return ['القسط الأول( 500 € / 500 $)']
    },
    methodes() {
      if (this.$i18n.locale === 'fr') {
        return ['Virement bancaire', 'Western Union']
      }
      if (this.$i18n.locale === 'en') {
        return ['Bank transfer', 'Western Union']
      }
      return ['تحويل بنكي', 'واسترن يونين']
    },
    reverse() {
      if (this.$i18n.locale === 'fr' || this.$i18n.locale === 'en') {
        return 'd-flex flex-row'
      }
      return 'd-flex flex-row-reverse'
    },
    items() {
      if (this.$i18n.locale === 'fr') {
        if (this.lead.admission === 'demande admission envoyée') {
          return [
            {
              Tâche: 'Données personnelles',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Cursus scolaire',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Choix du parcours',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Etat de la demande',
              _: {
                status: 'Votre demande en cour de traitement',
                variant: 'warning',
                icon: 'CheckIcon',
              },
            },
          ]
        }

        if (this.lead.admission === 'demande admission acceptée') {
          return [
            {
              Tâche: 'Données personnelles',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Cursus scolaire',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Choix du parcours',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Etat de la demande',
              _: {
                status: 'Votre demande a été acceptée',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Attestation de préinscription',
              _: { status: 'cliquez ici => ', variant: 'warning', icon: 'CheckIcon' },
            },
          ]
        }
        if (this.lead.admission === 'demande admission refusée') {
          return [
            {
              Tâche: 'Données personnelles',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Cursus scolaire',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Choix du parcours',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Etat de la demande',
              _: {
                status: 'Votre demande a été réfusée',
                variant: 'danger',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Attestation de préinscription',
              _: { status: 'On Hold', variant: 'warning', icon: 'CheckIcon' },
            },
          ]
        }
      }
      if (this.$i18n.locale === 'en') {
        if (this.lead.admission === 'demande admission envoyée') {
          return [
            {
              Task: this.$i18n.t('PersonalData'),
              _: {
                status: 'Your data has been sent successfully',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'School curriculumsss',
              _: {
                status: 'Your data has been sent successfully',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'Choice of class',
              _: {
                status: 'Your data has been sent successfully',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'Request Status',
              _: {
                status: 'Your request being processed!',
                variant: 'warning',
                icon: 'CheckIcon',
              },
            },
          ]
        }

        if (this.lead.admission === 'demande admission acceptée') {
          return [
            {
              Task: this.$i18n.t('PersonalData'),
              _: {
                status: 'Your data has been sent successfully',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'School curriculum',
              _: {
                status: 'Your data has been sent successfully',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'Choice of class',
              _: {
                status: 'Your data has been sent successfully',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'Request Status',
              _: {
                status: 'Your request has been accepted',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'Pre-registration certificate',
              _: { status: 'Click here => ', variant: 'warning', icon: 'CheckIcon' },
            },
          ]
        }

        if (this.lead.admission === 'demande admission refusée') {
          return [
            {
              Task: this.$i18n.t('PersonalData'),
              _: {
                status: 'Your data has been sent successfully',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'School curriculum',
              _: {
                status: 'Your data has been sent successfully',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'Choice of class',
              _: {
                status: 'Your data has been sent successfully',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'Request Status',
              _: {
                status: 'Your request has been refused',
                variant: 'danger',
                icon: 'CheckIcon',
              },
            },
          ]
        }
      }
      if (this.$i18n.locale === 'ar') {
        if (this.lead.admission === 'demande admission envoyée') {
          return [
            {
              الخانة: 'معلومات شخصية',
              _: {
                status: 'تم إرسال البيانات الخاصة بك بنجاح',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'المسار الدراسي',
              _: {
                status: 'تم إرسال البيانات الخاصة بك بنجاح',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'اختيار برنامج',
              _: {
                status: 'تم إرسال البيانات الخاصة بك بنجاح',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'حالة الطلب',
              _: {
                status: 'طلبك قيد المعالجة',
                variant: 'warning',
                icon: 'CheckIcon',
              },
            },
          ]
        }

        if (this.lead.admission === 'demande admission acceptée') {
          return [
            {
              الخانة: 'معلومات شخصية',
              _: {
                status: 'تم إرسال البيانات الخاصة بك بنجاح',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'المسار الدراسي',
              _: {
                status: 'تم إرسال البيانات الخاصة بك بنجاح',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'اختيار برنامج',
              _: {
                status: 'تم إرسال البيانات الخاصة بك بنجاح',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'حالة الطلب',
              _: {
                status: 'تم قبول طلبك',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'شهادة التسجيل المسبق',
              _: { status: 'انقر هنا => ', variant: 'warning', icon: 'CheckIcon' },
            },
          ]
        }
        if (this.lead.admission === 'demande admission refusée') {
          return [
            {
              الخانة: 'معلومات شخصية',
              _: {
                status: 'تم إرسال البيانات الخاصة بك بنجاح',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'المسار الدراسي',
              _: {
                status: 'تم إرسال البيانات الخاصة بك بنجاح',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'اختيار برنامج',
              _: {
                status: 'تم إرسال البيانات الخاصة بك بنجاح',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'حالة الطلب',
              _: {
                status: 'تم رفض طلبك',
                variant: 'danger',
                icon: 'CheckIcon',
              },
            },
          ]
        }
      }
      return []
    },
    baccalaureats() {
      if (this.$i18n.locale === 'fr') {
        return ['oui', 'equivalence']
      }
      if (this.$i18n.locale === 'en') {
        return ['yes', 'equivalence']
      }
      return ['نعم', 'المعادلة']
    },
    civilites() {
      if (this.$i18n.locale === 'fr' || this.$i18n.locale === 'en') {
        return ['Mr', 'Mlle', 'Mme']
      }
      return ['السيد', 'الأنسة', 'السيدة']
    },

    confirmation() {
      if (this.$i18n.locale === 'fr') {
        if (this.lead.admission === 'demande admission acceptée') {
          return [
            {
              Tâche: 'Dossier',
              _: {
                status: 'OK',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Paiement scolarité',
              _: { status: 'OK', variant: 'warning', icon: 'CheckIcon' },
            },
            {
              Tâche: 'Attestation inscription',
              _: {
                status: '',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Attestation de paiement',
              _: {
                status: '',
                variant: 'warning',
                icon: 'CheckIcon',
              },
            },
          ]
        }
        if (this.lead.admission === 'demande admission refusée') {
          return [
            {
              Tâche: 'Données personnelles',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Cursus scolaire',
              _: { status: 'On Hold', variant: 'warning', icon: 'CheckIcon' },
            },
            {
              Tâche: 'Choix du parcours',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Etat de la demande',
              _: {
                status: 'Votre demande a été réfusée',
                variant: 'danger',
                icon: 'CheckIcon',
              },
            },
            {
              Tâche: 'Attestation de préinscription',
              _: { status: 'On Hold', variant: 'warning', icon: 'CheckIcon' },
            },
          ]
        }
      }
      if (this.$i18n.locale === 'en') {
        if (this.lead.admission === 'demande admission acceptée') {
          return [
            {
              Task: 'Record',
              _: {
                status: 'OK',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'Tuition payment',
              _: { status: 'OK', variant: 'warning', icon: 'CheckIcon' },
            },
            {
              Task: 'Registration certificate',
              _: {
                status: '',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'Proof of payment',
              _: {
                status: '',
                variant: 'warning',
                icon: 'CheckIcon',
              },
            },
          ]
        }
        if (this.lead.admission === 'demande admission refusée') {
          return [
            {
              Task: 'Données personnelles',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'Cursus scolaire',
              _: { status: 'On Hold', variant: 'warning', icon: 'CheckIcon' },
            },
            {
              Task: 'Choix du parcours',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'Etat de la demande',
              _: {
                status: 'Votre demande a été réfusée',
                variant: 'danger',
                icon: 'CheckIcon',
              },
            },
            {
              Task: 'Attestation de préinscription',
              _: { status: 'On Hold', variant: 'warning', icon: 'CheckIcon' },
            },
          ]
        }
      }
      if (this.$i18n.locale === 'ar') {
        if (this.lead.admission === 'demande admission acceptée') {
          return [
            {
              الخانة: 'ملف',
              _: {
                status: 'OK',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'دفع الرسوم الدراسية',
              _: { status: 'OK', variant: 'warning', icon: 'CheckIcon' },
            },
            {
              الخانة: 'شهادة التسجيل',
              _: {
                status: '',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'إثبات دفع',
              _: {
                status: '',
                variant: 'warning',
                icon: 'CheckIcon',
              },
            },
          ]
        }
        if (this.lead.admission === 'demande admission refusée') {
          return [
            {
              الخانة: 'Données personnelles',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'Cursus scolaire',
              _: { status: 'On Hold', variant: 'warning', icon: 'CheckIcon' },
            },
            {
              الخانة: 'Choix du parcours',
              _: {
                status: 'Vos données ont été envoyées avec succès',
                variant: 'success',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'Etat de la demande',
              _: {
                status: 'Votre demande a été réfusée',
                variant: 'danger',
                icon: 'CheckIcon',
              },
            },
            {
              الخانة: 'Attestation de préinscription',
              _: { status: 'On Hold', variant: 'warning', icon: 'CheckIcon' },
            },
          ]
        }
      }
      return []
    },
    mySpecialites() {
      return this.specialites.filter(item => item.parcour.id === this.parcour.id)
    },
    ok() {
      if (this.lead.admission === 'demande admission non envoyée') {
        return false
      }
      return true
    },
    myNiveaux() {
      if (this.$i18n.locale === 'fr') {
        if (this.parcour.nom === 'Licences') {
          return ['première année', 'deuxième année', 'troisième année']
        }
        if (this.parcour.nom === 'Mastères') {
          return ['première année', 'deuxième année']
        }
      }
      if (this.$i18n.locale === 'en') {
        if (this.parcour.nom === 'Licences') {
          return ['First year', 'Second year', 'Third year']
        }
        if (this.parcour.nom === 'Mastères') {
          return ['First year', 'Second year']
        }
      }
      if (this.$i18n.locale === 'ar') {
        if (this.parcour.nom === 'Licences') {
          return ['سنة أولى', 'سنة ثانية', 'سنة ثالثة']
        }
        if (this.parcour.nom === 'Mastères') {
          return ['سنة أولى', 'سنة ثانية']
        }
      }
      return []
    },

    inscri() {
      if (this.lead.inscription === 'demande inscription non envoyée') {
        return false
      }
      return true
    },
    paier() {
      if (this.lead.paiement === 'demande paiement non envoyée') {
        return false
      }
      return true
    },
    speciality() {
      return this.specialites.find(item => item.id === this.lead.specialite_id)
    },
  },
  created() {
    this.getUsers()
    this.getParcours()
    this.getSpecialites()
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    generatePaiement() {
      this.$refs.paiement.generatePdf()
    },

    generateInscription() {
      this.$refs.inscription.generatePdf()
    },
    async getParcours() {
      await axios.get('/parcours/').then(response => {
        this.parcours = response.data
      })
    },
    getUsers() {
      axios.get('/auth/users/', {}).then(response => {
        const users = response.data
        this.lead = users.find(user => user.id === Number(localStorage.getItem('id')))
      })
    },
    async getSpecialites() {
      await axios.get('/specialites/').then(response => {
        this.specialites = response.data
      })
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Inscription effectuée avec succès',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    async validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(async success => {
          if (success && this.lead.admission === 'demande admission non envoyée') {
            const token = localStorage.getItem('token')
            axios.defaults.headers.common.Authorization = `Token ${token}`
            const formData = new FormData()
            formData.append('niveau', this.lead.niveau)
            formData.append('dateNaissance', this.lead.dateNaissance)
            formData.append('civilite', this.lead.civilite)
            formData.append('nom', this.lead.nom)
            formData.append('typeBac', this.lead.typeBac)
            formData.append('typeDiplome', this.lead.typeDiplome)
            formData.append('cin', this.lead.cin)
            formData.append('prenom', this.lead.prenom)
            formData.append('lieuNaissance', this.lead.lieuNaissance)
            formData.append('email', this.lead.email)
            formData.append('telephone', this.lead.telephone)
            formData.append('uploadBac', this.lead.uploadBac)
            formData.append('uploadDiplome', this.lead.uploadDiplome)
            formData.append('adress', this.lead.adress)
            formData.append('bac', this.lead.bac)
            formData.append('diplome', this.lead.diplome)
            formData.append('anneeBac', this.lead.anneeBac)
            formData.append('anneeDiplome', this.lead.anneeDiplome)
            formData.append('pays', this.lead.pays)
            formData.append('specialite', this.specialite.id)
            formData.append('username', `${this.lead.nom}  ${this.prenom}`)
            this.load = 'true'
            await axios
              .put(`/auth/update_profile/${localStorage.getItem('id')}/`, formData)
              .then(() => {
                this.load = 'false'
                setTimeout(() => {
                  this.showToast(
                    'success',
                    'top-center',
                    this.$i18n.t('InscSuccess'),
                  )
                }, 1000)
                this.getUsers()
                resolve(true)
              })
              .catch(error => {
                setTimeout(() => {
                  this.showToast('danger', 'top-center', error.toString())
                }, 1000)
              })
          } else if (
            this.lead.admission === 'demande admission envoyée'
            || this.lead.admission === 'demande admission acceptée'
          ) {
            resolve(true)
          } else if (this.lead.admission === 'demande admission refusée') {
            setTimeout(() => {
              this.showToast(
                'danger',
                'top-center',
                "Votre demande d'admission a été réfusée veuillez vérifier votre email",
              )
            }, 1000)
            resolve(true)
          } else if (this.lead.admission === 'demande admission acceptée') {
            setTimeout(() => {
              this.showToast(
                'success',
                'top-center',
                "Votre demande d'admission a été acceptée",
              )
            }, 1000)
            resolve(true)
          } else {
            setTimeout(() => {
              this.showToast('danger', 'top-center', this.$i18n.t('VeuillezDonn'))
            }, 1000)
            reject()
          }
        })
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

    admission() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success && this.lead.admission === 'demande admission acceptée') {
            resolve(true)
          } else if (this.lead.admission === 'demande admission refusée') {
            reject()
            setTimeout(() => {
              this.showToast('danger', 'top-center', this.$i18n.t('VeuillezAdm'))
            }, 1000)
          } else {
            reject()
            setTimeout(() => {
              this.showToast('danger', 'top-center', this.$i18n.t('VeuillezAdm'))
            }, 1000)
          }
        })
      })
    },

    inscription() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(async success => {
          if (success && this.lead.inscription === 'demande inscription non envoyée') {
            const token = localStorage.getItem('token')
            axios.defaults.headers.common.Authorization = `Token ${token}`
            const formData = new FormData()
            formData.append('uploadCin', this.lead.uploadCin)
            formData.append('nom', this.lead.nom)
            formData.append('email', this.lead.email)
            formData.append('uploadNote', this.lead.uploadNote)
            formData.append('uploadActNaiss', this.lead.uploadActNaiss)
            formData.append('inscription', 'demande inscription envoyée')
            this.load = 'true'
            await axios
              .put(`/auth/inscription/${localStorage.getItem('id')}/`, formData)
              .then(() => {
                this.load = 'false'
                setTimeout(() => {
                  this.showToast('success', 'top-center', this.$i18n.t('InscSuccess'))
                }, 1000)
                this.getUsers()
                reject()
              })
              .catch(error => {
                setTimeout(() => {
                  this.showToast('danger', 'top-center', error.toString())
                }, 1000)
              })
          } else if (this.lead.inscription === 'demande inscription acceptée') {
            resolve(true)
          } else if (this.lead.inscription === 'demande inscription envoyée') {
            setTimeout(() => {
              this.showToast('danger', 'top-center', this.$i18n.t('VeuillezIns'))
            }, 1000)
            reject()
          } else {
            setTimeout(() => {
              this.showToast('danger', 'top-center', this.$i18n.t('VeuillezDonn'))
            }, 1000)
            reject()
          }
        })
      })
    },

    paiement() {
      return new Promise((resolve, reject) => {
        this.$refs.payement.validate().then(async success => {
          if (success && this.lead.paiement === 'demande paiement non envoyée') {
            const token = localStorage.getItem('token')
            axios.defaults.headers.common.Authorization = `Token ${token}`
            const formData = new FormData()
            formData.append('methodePaiement', this.lead.methodePaiement)
            formData.append('nom', this.lead.nom)
            formData.append('email', this.lead.email)
            formData.append('devise', this.lead.devise)
            formData.append('tranche', this.lead.tranche)
            formData.append('paiement', 'demande paiement envoyée')
            formData.append('uploadPaiement', this.lead.uploadPaiement)
            this.load = 'true'
            await axios
              .put(`/auth/paiement/${localStorage.getItem('id')}/`, formData)
              .then(() => {
                this.load = 'false'
                setTimeout(() => {
                  this.showToast(
                    'success',
                    'top-center',
                    this.$i18n.t('InscSuccess'),
                  )
                }, 1000)
                this.getUsers()
                reject()
              })
              .catch(error => {
                setTimeout(() => {
                  this.showToast('danger', 'top-center', error.toString())
                }, 1000)
              })
          } else if (this.lead.paiement === 'demande paiement acceptée') {
            resolve(true)
          } else if (this.lead.paiement === 'demande paiement envoyée') {
            setTimeout(() => {
              this.showToast(
                'danger',
                'top-center',
                this.$i18n.t('VeuillezIns'),
              )
            }, 1000)
            reject()
          } else if (this.lead.paiement === 'demande paiement refusée') {
            setTimeout(() => {
              this.showToast('danger', 'top-center', this.$i18n.t('Votre demande a été réfusée'))
            }, 1000)
            reject()
          } else {
            setTimeout(() => {
              this.showToast('danger', 'top-center', this.$i18n.t('VeuillezDonn'))
            }, 1000)
            reject()
          }
        })
      })
    },
  },
}

</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
